import React from 'react';
import SearchItem from '../SearchItem/SearchItem';
import styles from './SearchResults.module.scss';

const SearchResults: React.FC<{
  results: any;
  selectedIds: string[];
  onSelectChanged: any;
}> = (props: any) => {
  return (
    <div className={styles.SearchResults} data-testid="SearchResults">
      {props.results?.map((item: any) => (
        <SearchItem
          key={`searchitem-${item.id}`}
          content={item}
          selected={props.selectedIds.indexOf(item.id) !== -1}
          onSelectChanged={props.onSelectChanged}
        />
      ))}
    </div>
  );
};

export default SearchResults;

import { IonIcon } from '@ionic/react';
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Dialog from '@reach/dialog';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  helpCircleOutline,
  pencilSharp,
} from 'ionicons/icons';
import React from 'react';
import Icon, {
  ConditionIconIdentifier,
  ConditionIcons,
  FormatIconIdentifier,
  FormatIcons,
} from '../../Icon/Icon';
import styles from './BatchEdit.module.scss';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { useSnackbar } from 'notistack';
import CatalogEdit from '../../CatalogEdit/CatalogEdit';

interface Props {
  data: any;
}

const generateIntitalCategoriesForTags = (data: any): Tag[] => {
  const categories = Array.from<string>(
    new Set(
      data.items.reduce(
        (value: any, current: any) =>
          current.categories ? value.concat(current.categories) : value,
        []
      )
    )
  );
  return categories.map((category: string) => ({
    id: category,
    name: category,
  }));
};

const BatchEdit: React.FC<Props> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showDialog, setShowDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState<Tag[]>(
    generateIntitalCategoriesForTags(data)
  );
  const [categorySuggestions] = React.useState([
    { id: 'A', name: 'AND' },
    { id: 'B', name: 'BOLT' },
  ]);
  const open = () => setShowDialog(true);
  const close = () => {
    if (!loading) setShowDialog(false);
  };

  const store = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      enqueueSnackbar('Update has been saved', {
        variant: 'success',
      });
      close();
    }, 1000);
  };

  const handleCategoryAdded = (category: Tag) => {
    setCategories([...categories, category]);
  };

  const handleCategoryDeleted = (index: number) => {
    const newTags = categories.slice(0);
    newTags.splice(index, 1);
    setCategories(newTags);
  };

  function TagComponent({
    tag,
    removeButtonText,
    onDelete,
  }: {
    tag: Tag;
    removeButtonText: string;
    onDelete: any;
  }) {
    return (
      <Chip
        className={styles.Chip}
        label={tag.name}
        onDelete={onDelete}
        color="primary"
      />
    );
  }

  return (
    <>
      <Button
        type="button"
        variant="outlined"
        onClick={open}
        startIcon={<IonIcon icon={pencilSharp} />}>
        Batch Edit
      </Button>

      <Dialog isOpen={showDialog} onDismiss={close}>
        <div className={styles.ModalHeader}>
          <h1 className={styles.ModalTitle}>Batch edit attributes</h1>
          <button
            className={styles.CloseButton}
            onClick={close}
            disabled={loading}>
            <IonIcon icon={closeCircleOutline} ariaLabel="Close Modal" />
          </button>
        </div>
        <div className={styles.ModalContent}>
          <h3 className={styles.Section}>Categories</h3>
          <ReactTags
            tagComponent={TagComponent}
            tags={categories}
            onDelete={handleCategoryDeleted}
            suggestions={categorySuggestions}
            onAddition={handleCategoryAdded}
            placeholderText="Add Category"
          />
          <h3 className={styles.Section}>Catalogs</h3>
          <CatalogEdit />
          <h3 className={styles.Section}>Condition</h3>
          <div>
            {Object.keys(ConditionIcons).map((icon: string) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    onChange={() => {}}
                    name="checkedB"
                    color="primary"
                  />
                }
                labelPlacement="top"
                label={<Icon icon={icon as ConditionIconIdentifier}></Icon>}
              />
            ))}
          </div>
          <h3 className={styles.Section}>Formats</h3>
          <div>
            {Object.keys(FormatIcons).map((icon: string) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    onChange={() => {}}
                    name="checkedB"
                    color="primary"
                  />
                }
                labelPlacement="top"
                label={<Icon icon={icon as FormatIconIdentifier}></Icon>}
              />
            ))}
          </div>
          <h3 className={styles.Section}>Check</h3>
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={false}
                  onChange={() => {}}
                  name="checkedB"
                  color="primary"
                />
              }
              labelPlacement="top"
              label={<IonIcon icon={closeCircleOutline}></IonIcon>}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={false}
                  onChange={() => {}}
                  name="checkedB"
                  color="primary"
                />
              }
              labelPlacement="top"
              label={<IonIcon icon={helpCircleOutline}></IonIcon>}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={false}
                  onChange={() => {}}
                  name="checkedB"
                  color="primary"
                />
              }
              labelPlacement="top"
              label={<IonIcon icon={checkmarkCircleOutline}></IonIcon>}
            />
          </div>
        </div>
        <div className={styles.ModalActions}>
          <Button
            type="button"
            variant="outlined"
            disabled={loading}
            onClick={close}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={store}>
            {loading ? 'Loading ...' : 'Save'}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default BatchEdit;

import { IonIcon } from '@ionic/react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { downloadOutline } from 'ionicons/icons';
import { useSnackbar } from 'notistack';
import React from 'react';
import styles from './DownloadButton.module.scss';

const DownloadButton: React.FC = () => {
  const [
    downloadButtonEl,
    setDownloadButtonEl,
  ] = React.useState<null | HTMLElement>(null);
  const handleDownloadMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDownloadButtonEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setDownloadButtonEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();
  const downloadClicked = () => {
    enqueueSnackbar(
      "The download is going to be prepared. You'll rechieve an email shortly.",
      {
        variant: 'info',
      }
    );
    handleDownloadClose();
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleDownloadMenuOpen}>
        <IonIcon icon={downloadOutline} className={styles.downloadButtonIcon} />
        Download
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={downloadButtonEl}
        keepMounted
        open={Boolean(downloadButtonEl)}
        onClose={handleDownloadClose}>
        <MenuItem value="new" onClick={downloadClicked}>
          Download images
        </MenuItem>
        <MenuItem value="new" onClick={downloadClicked}>
          Download as CSV
        </MenuItem>
        <MenuItem value="new" onClick={downloadClicked}>
          Prepare for Print
        </MenuItem>
      </Menu>
    </>
  );
};

export default DownloadButton;

import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import Icon, { ConditionIconIdentifier, ConditionIcons } from '../Icon/Icon';
import styles from './ConditionSelect.module.scss';

const ConditionSelect: React.FC<{
  conditions: ConditionIconIdentifier[];
  onChange: any;
}> = ({ conditions = [], onChange }) => {
  const handleChange = (event: any, checked: boolean) => {
    let newSelection = [...conditions];
    if (checked) {
      newSelection.push(event.target.name);
    } else {
      newSelection.splice(newSelection.indexOf(event.target.name), 1);
    }
    onChange(newSelection);
  };

  return (
    <div className={styles.ConditionSelect} data-testid="ConditionSelect">
      {Object.keys(ConditionIcons).map((icon) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={conditions.includes(icon as ConditionIconIdentifier)}
              onChange={handleChange}
              name={icon}
              color="primary"
            />
          }
          labelPlacement="top"
          label={<Icon icon={icon as ConditionIconIdentifier}></Icon>}
        />
      ))}
    </div>
  );
};

export default ConditionSelect;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { FeatureTooglesProvider } from './components/FeatureToggle/FeatureTogglesProvider';
import { createBrowserHistory } from 'history';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_ID as string}
      audience="https://lotarchive.dev"
      onRedirectCallback={(appState: any) => {
        if (appState.redirect && appState.redirect !== '/') {
          const history = createBrowserHistory();
          history.replace(
            (appState && appState.redirect) || window.location.pathname
          );
        }
      }}
      redirectUri={window.location.origin}>
      <FeatureTooglesProvider>
        <App />
      </FeatureTooglesProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

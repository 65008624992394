import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { FeatureToggleContext, FeatureToggleIdentifier } from './context';

export const FeatureTooglesProvider: React.FC = (props) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [isLoaded, setIsLoaded] = useState(false);
  const [features, setFeatures] = useState<FeatureToggleIdentifier[]>([]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        if (token) {
          const data = JSON.parse(atob(token.split('.')[1]));
          setFeatures(data['https://lotarchive']?.features || []);
          setIsLoaded(true);
        }
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const providerValue = {
    features,
    featuresLoaded: isLoaded,
  };

  return (
    <FeatureToggleContext.Provider value={providerValue}>
      {props.children}
    </FeatureToggleContext.Provider>
  );
};

import { FormControlLabel, Switch } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import apiService from '../../services/api/api.serivce';
import FeatureToggle from '../FeatureToggle/FeatureToggle';
import styles from './DetailPageInfo.module.scss';

const currencyFormatter = new Intl.NumberFormat('en-US', {});

const DetailPageInfo: React.FC<{ data: any }> = ({ data }) => {
  const [imageRecognition, setImageRecognition] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleImageRecognitionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (loading) return;

    apiService
      .changeImageRecognition(data.id, checked)
      .then(() => {
        enqueueSnackbar('Image recognition was changed for this group', {
          variant: 'success',
        });
        setImageRecognition(checked);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar('Could not store. Please try again', {
          variant: 'warning',
        });
      });
  };

  const soldForRange = [
    ...data.items.map((l: any) => l.sold_for_eur).filter((p: any) => p > 0),
  ];
  return (
    <div className={styles.DetailPageInfo} data-testid="DetailPageInfo">
      <h3 className={styles.Section}>Tags</h3>
      {data.tags.join(', ')}
      <h3 className={styles.Section}>Image recogintion</h3>
      <FeatureToggle featureName="DETAIL_CHANGE_IMAGE_RECOGNITION">
        <FormControlLabel
          control={
            <Switch
              disabled={loading}
              checked={imageRecognition}
              onChange={handleImageRecognitionChange}
              name="imageRecognition"
              color="primary"
            />
          }
          label={
            imageRecognition
              ? 'Group is indexed for image recognition'
              : 'Group is currently not indexed for image recognition'
          }
        />
      </FeatureToggle>
      <FeatureToggle
        featureName="DETAIL_CHANGE_IMAGE_RECOGNITION"
        showOnlyWhenDisabled>
        {imageRecognition
          ? 'Group is indexed for image recognition'
          : 'Group is currently not indexed for image recognition'}
      </FeatureToggle>
      <h3 className={styles.Section}>
        Sold for <em>(EUR)</em>
      </h3>
      {soldForRange.length !== 0 ? (
        <div className={styles.prices}>
          <div>{currencyFormatter.format(Math.min(...soldForRange))}</div>
          <div className={styles.priceSeparator}>-</div>
          <div>{currencyFormatter.format(Math.max(...soldForRange))}</div>
        </div>
      ) : (
        'never sold yet'
      )}
    </div>
  );
};

export default DetailPageInfo;

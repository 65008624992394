import { IonIcon } from '@ionic/react';
import { Button, Tooltip } from '@material-ui/core';
import { warning } from 'ionicons/icons';
import React from 'react';
import { Icon, IconIdentifier } from '../Icon/Icon';
import styles from './DetailPageAggregation.module.scss';
import { Link as RouterLink } from 'react-router-dom';

const generateLink = (
  catalogs: any,
  categories: any,
  conditions: any,
  formats: any,
  checks: any
) => {
  const filters = {
    conditions: conditions.length ? conditions : undefined,
    formats: formats.length ? formats : undefined,
    categories: categories.length ? categories : undefined,
  };
  return '/search/?q=' + catalogs + '&filters=' + btoa(JSON.stringify(filters));
};

const DetailPageAggregation: React.FC<{ data: any }> = ({ data }) => {
  const catalogs = Array.from(
    new Set(
      data.items.reduce(
        (value: any, current: any) =>
          current.catalogNumbers
            ? value.concat(
                current.catalogNumbers.map((c: any) => c.type + ' ' + c.number)
              )
            : value,
        []
      )
    )
  );
  const categories = Array.from(
    new Set(
      data.items.reduce(
        (value: any, current: any) =>
          current.categories
            ? value.concat(current.categories.map((c: any) => c.name))
            : value,
        []
      )
    )
  );
  const conditions: IconIdentifier[] = Array.from(
    new Set(
      data.items.reduce(
        (value: any, current: any) =>
          current.conditions ? value.concat(current.conditions) : value,
        []
      )
    )
  );
  const formats: IconIdentifier[] = Array.from(
    new Set(
      data.items.reduce(
        (value: any, current: any) =>
          current.formats ? value.concat(current.formats) : value,
        []
      )
    )
  );
  const checks = Array.from(
    new Set(
      data.items.reduce(
        (value: any, current: any) =>
          current.check ? value.concat(current.check) : value,
        []
      )
    )
  );

  return (
    <div
      className={styles.DetailPageAggregation}
      data-testid="DetailPageAggregation">
      <div className={styles.Container}>
        <div className={styles.BlockHalf}>
          <h3 className={styles.Section}>Catalogs</h3>
          <div>{catalogs.join(' ')}</div>
        </div>
        <div className={styles.BlockHalf}>
          <h3 className={styles.Section}>
            Categories
            {categories.length > 1 ? (
              <Tooltip arrow title="Multiple categories within this group!">
                <span>
                  <IonIcon className={styles.warning} icon={warning} />
                </span>
              </Tooltip>
            ) : (
              <></>
            )}
          </h3>
          <div>{categories}</div>
        </div>
        <div className={styles.BlockThird}>
          <h3 className={styles.Section}>Condition</h3>
          <div>
            {conditions.map((icon: IconIdentifier) => (
              <Icon icon={icon}></Icon>
            ))}
          </div>
        </div>
        <div className={styles.BlockThird}>
          <h3 className={styles.Section}>format</h3>
          <div>
            {formats.map((icon: IconIdentifier) => (
              <Icon icon={icon}></Icon>
            ))}
          </div>
        </div>
        <div className={styles.BlockThird}>
          <h3 className={styles.Section}>check</h3>
          <div>{checks}</div>
        </div>
      </div>
      <div className={styles.Actions}>
        <Button
          component={RouterLink}
          to={generateLink(catalogs, categories, conditions, formats, checks)}
          type="button">
          Find Similar
        </Button>
      </div>
    </div>
  );
};

export default DetailPageAggregation;

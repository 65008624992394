import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import styles from './Hero.module.scss';
import { Button, TextField, withStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Uploadly from '../Uploadly/Uploadly';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
})(TextField);

const Hero: React.FC = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const searchString = query.get('q');

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (searchString) {
      setValue(searchString);
    }
  }, [searchString]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    handleSearch(value || '');
    event.preventDefault();
  };

  const handleSearch = (value: string): void => {
    history.push(`/search?q=${value}`);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className={styles.Hero} data-testid="Hero">
      <div className={styles.container}>
        <h1>Lot Archive</h1>

        <div className={styles.searchBox}>
          <form onSubmit={onSubmit}>
            <CssTextField
              id="herp-searchfiled"
              label="Search the Archive"
              variant="outlined"
              onChange={onChange}
              value={value}
              autoFocus={true}
            />
            <Button type="submit" variant="contained" color="primary">
              Search
            </Button>
          </form>
        </div>
      </div>
      <Uploadly></Uploadly>
    </div>
  );
};

export default Hero;

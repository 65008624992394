export const unGroupEntry = async (
  id: string,
  token: string,
  keepTags: boolean = false
): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/details/${id}/disconnect`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ keepTags }),
    }
  );

  if (response.status === 202) {
    return;
  } else {
    throw Error('could not disconnect');
  }
};

export const changeImageRecognition = async (
  id: string,
  state: boolean
): Promise<any> => {
  const response = await fetch(`/${id}/imageRecognition`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ state }),
  });

  return await response.json();
};

import { IonIcon } from '@ionic/react';
import React, { useReducer } from 'react';
import styles from './SearchFacets.module.scss';
import { refreshOutline } from 'ionicons/icons';
import { Button } from '@material-ui/core';
import FacetList from '../FacetList/FacetList';
import { useHistory, useLocation } from 'react-router-dom';
import FacetInput from '../FacetInput/FacetInput';
import FacetDateInput from '../FacetDateInput/FacetDateInput';

enum FACET_KEY {
  DATE = 'date',
  STARTING_BID = 'starting_bid',
  SOLD_FOR = 'sold_for',
  SOLD = 'sold',
  CATEGORY = 'categories',
  CURRENCY = 'currency',
  CATALOG = 'catalogs',
  MARK_FOR_IMAGE_RECO = 'imageRecognition',
  AUCTIONEER = 'auctioneer',
  AUCTION = 'auction',
  CATALOG_PARTS = 'catalogParts',
  CONDITION = 'conditions',
  TYPE = 'formats',
  CHECK = 'check',
}

const FACET_TITLES: { [key in FACET_KEY]: string } = {
  date: 'Date',
  starting_bid: 'Starting bid (EUR)',
  sold_for: 'Sold for (EUR)',
  sold: 'Sold',
  categories: 'Category',
  currency: 'Currency',
  catalogs: 'Catalog',
  auctioneer: 'Auctioneer',
  auction: 'Auction',
  catalogParts: 'Catalogpart',
  conditions: 'Condition',
  formats: 'Format',
  check: 'Check',
  imageRecognition: 'Image Recognition',
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface FACET_REDUCER_ACTION_DATA {
  key: FACET_KEY;
  data: any;
}
interface FACET_REDUCER_ACTION {
  type: 'RESET' | 'RESET_FACET' | 'SET_FACET';
  facet?: FACET_REDUCER_ACTION_DATA;
}

const facetReducer = (oldState: any, action: FACET_REDUCER_ACTION) => {
  let newState: {
    [key: string]: any;
  } = {};

  switch (action.type) {
    case 'RESET':
      return {};
    case 'RESET_FACET':
      newState = { ...oldState };
      delete newState.selected;
      delete newState[(action.facet as FACET_REDUCER_ACTION_DATA).key];

      return {
        ...newState,
        ...{ selected: Object.keys(newState).length > 0 },
      };
    case 'SET_FACET':
      newState[
        (action.facet as FACET_REDUCER_ACTION_DATA).key
      ] = (action.facet as FACET_REDUCER_ACTION_DATA).data;

      return {
        ...oldState,
        ...newState,
        ...{ selected: true },
      };
  }
};

const SearchFacets: React.FC<{ facets: any }> = (props) => {
  const queryParams = useQuery();
  const history = useHistory();

  const getFacetsFromUrl = () => {
    const filterParameter = queryParams.get('filters');

    if (filterParameter) {
      const parsedFilters = JSON.parse(atob(filterParameter));
      parsedFilters.selected = true;
      return parsedFilters;
    } else {
      return {
        selected: false,
      };
    }
  };

  const [selectedFacets, dispatch] = useReducer(
    facetReducer,
    {},
    getFacetsFromUrl
  );
  const isFiltersActive = selectedFacets.selected;

  const resetFilter = () => {
    dispatch({ type: 'RESET' });

    queryParams.delete('filters');

    history.push({
      pathname: history.location.pathname,
      search: queryParams.toString(),
    });
  };

  const setFilters = () => {
    const filters = { ...selectedFacets };
    delete filters.selected;
    queryParams.set('filters', btoa(JSON.stringify(filters)));

    history.push({
      pathname: history.location.pathname,
      search: queryParams.toString(),
    });
  };

  const renderFacets = () => {
    const facets: FACET_KEY[] = [
      FACET_KEY.DATE,
      FACET_KEY.STARTING_BID,
      FACET_KEY.SOLD_FOR,
      FACET_KEY.AUCTIONEER,
      FACET_KEY.AUCTION,
      FACET_KEY.CATALOG_PARTS,
      FACET_KEY.CATEGORY,
      FACET_KEY.CATALOG,
      FACET_KEY.CONDITION,
      FACET_KEY.TYPE,
      FACET_KEY.CURRENCY,
      FACET_KEY.SOLD,
      FACET_KEY.MARK_FOR_IMAGE_RECO,
    ];

    const availableFacets = facets.reduce((filteredFacets, currentFacet) => {
      if (!!props.facets[currentFacet]) filteredFacets.push(currentFacet);
      return filteredFacets;
    }, [] as FACET_KEY[]);

    return availableFacets.map((facetKey: FACET_KEY) => {
      let component;
      switch (facetKey) {
        case 'date':
          component = (
            <FacetDateInput
              key={facetKey}
              facetKey={facetKey}
              facetData={props.facets[facetKey] as any}
              selectedData={selectedFacets[facetKey]}
              onSelectDispatch={dispatch}
              name={FACET_TITLES[facetKey]}
            />
          );
          break;
        case 'starting_bid':
        case 'sold_for':
          component = (
            <FacetInput
              key={facetKey}
              facetKey={facetKey}
              facetData={props.facets[facetKey] as any}
              selectedData={selectedFacets[facetKey]}
              onSelectDispatch={dispatch}
              name={FACET_TITLES[facetKey]}
            />
          );
          break;

        case 'auctioneer':
        case 'auction':
        case 'catalogParts':
        case 'categories':
        case 'catalogs':
        case 'check': // TODO: create a different styled List
        case 'currency': // TODO: create a different styled List
          component = (
            <FacetList
              key={facetKey}
              facetKey={facetKey}
              facetData={props.facets[facetKey] as any}
              selectedData={selectedFacets[facetKey]}
              onSelectDispatch={dispatch}
              name={FACET_TITLES[facetKey]}
            />
          );
          break;
        case 'conditions':
        case 'formats':
          component = (
            <FacetList
              key={facetKey}
              facetKey={facetKey}
              facetData={props.facets[facetKey] as any}
              selectedData={selectedFacets[facetKey]}
              onSelectDispatch={dispatch}
              type="icons"
              name={FACET_TITLES[facetKey]}
            />
          );
          break;
        case 'sold':
        case 'imageRecognition':
          component = (
            <FacetList
              key={facetKey}
              facetKey={facetKey}
              facetData={props.facets[facetKey] as any}
              selectedData={selectedFacets[facetKey]}
              onSelectDispatch={dispatch}
              type="bool"
              name={FACET_TITLES[facetKey]}
            />
          );
          break;
      }

      return component;
    });
  };

  return (
    <div className={styles.SearchFacets} data-testid="SearchFacets">
      <div className={styles.header}>
        <h2>Filters</h2>
        <button
          className={styles.resetButton}
          disabled={!isFiltersActive}
          aria-label="logout"
          onClick={resetFilter}>
          <IonIcon icon={refreshOutline} />
        </button>
      </div>

      {renderFacets()}

      <Button
        type="button"
        variant="contained"
        color="primary"
        className={styles.submitButton}
        disabled={!isFiltersActive}
        onClick={setFilters}>
        Apply Filters
      </Button>
    </div>
  );
};

export default SearchFacets;

type newLotData = {
  auctionId: number;
  lotNumber: string;
  description: string;
  categories: [];
  destinations?: [];
  catalogs?: [];
  conditions?: [];
  formats?: [];
  startingPrice: number;
  sold: boolean;
  soldFor?: number;
  currency: string;
};

export const storeNewLot = async (data: newLotData): Promise<any> => {
  const response = await fetch(`/lotEntries`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });

  return await response.json();
};

import React, { useState } from 'react';
import styled from 'styled-components';
import styles from './Uploadly.module.scss';
import Uploady, {
  useItemStartListener,
  useItemFinalizeListener,
} from '@rpldy/uploady';
import { getMockSenderEnhancer } from '@rpldy/mock-sender';
import UploadDropZone from '@rpldy/upload-drop-zone';
import withPasteUpload from '@rpldy/upload-paste';
import UploadPreview from '@rpldy/upload-preview';
import { BatchItem } from '@rpldy/shared';
import { OnProgress, SendMethod, SendOptions, SendResult } from '@rpldy/sender';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import apiService from '../../services/api/api.serivce';

const mockSenderEnhancer = getMockSenderEnhancer({});

const PreviewContainer = styled.div`
  margin-top: 20px;

  img {
    max-width: 400px;
  }
`;

const StyledInput = styled.input`
  width: 400px;
  height: 22px;
  font-size: 20px;
  margin: 10px 0;
`;

const UploadStatus = () => {
  const [status, setStatus] = useState(null);

  // @ts-ignore
  useItemStartListener(() => setStatus(''));
  // @ts-ignore
  useItemFinalizeListener(() => setStatus(''));

  return status;
};

// todo: this might not be needed, try to remove it and see if it still runs
withPasteUpload(UploadDropZone);

const PasteInput = withPasteUpload(StyledInput);

const Uploadly: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const sendRequest = async (items: BatchItem[]): Promise<any> => {
    const token = await getAccessTokenSilently();
    const fileName = await apiService.uploadFileForDlr(token, items[0].file);
    history.push(`/dlrSearch?file=${fileName}`);
    return '';
  };

  const sender: SendMethod = (
    items: BatchItem[],
    url: string | undefined,
    options: SendOptions,
    onProgress?: OnProgress
  ): SendResult => {
    return {
      request: sendRequest(items),
      abort: () => false,
      senderType: 'CUSTOM',
    };
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Uploady
          debug
          enhancer={mockSenderEnhancer}
          send={sender}
          destination={{ url: 'not used' }}>
          <UploadDropZone
            className={styles.dropZone}
            onDragOverClassName="drag-over"
            grouped
            maxGroupSize={3}>
            <span>Drag & Drop File(s) Here to search.</span>
          </UploadDropZone>
          <PasteInput extraProps={{ placeholder: 'paste inside to upload' }} />
          <UploadStatus />
          <PreviewContainer>
            <UploadPreview />
          </PreviewContainer>
        </Uploady>
      </div>
    </div>
  );
};

export default Uploadly;

import { useAuth0 } from '@auth0/auth0-react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ActionSelect from '../../components/ActionSelect/ActionSelect';
import DownloadButton from '../../components/DownloadButton/DownloadButton';
import EmptyResults from '../../components/EmptyResults/EmptyResults';
import FeatureToggle from '../../components/FeatureToggle/FeatureToggle';
import NewEntryButton from '../../components/NewEntryButton/NewEntryButton';
import SaveSearch from '../../components/SaveSearch/SaveSearch';
import SearchFacets from '../../components/SearchFacets/SearchFacets';
import SearchResults from '../../components/SearchResults/SearchResults';
import apiService from '../../services/api/api.serivce';
import styles from './Search.module.scss';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

const Search: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();
  const searchString = query.get('q') || '';
  const filterParameter = query.get('filters') || '';
  const sortParameter = query.get('sort') || 'score';
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setLoading] = useState(true);
  const [selectedSortOrder, setSortOrder] = useState(sortParameter);
  const [searchResults, setSearchResults] = useState({} as any);
  const [searchFacets, setSearchFacets] = useState({} as any);
  const page = parseInt(query.get('page') || '1', 10);
  const [pages, setPages] = useState(0);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [globalSelect, setGlobalSelect] = useState(false);
  const [globalIndeterminate, setGlobalIndeterminate] = useState(false);

  const handleItemSelectChanged = (id: string, checked: boolean) => {
    let newSelectedIds;
    if (checked) {
      newSelectedIds = [...selectedIds, id];
    } else {
      newSelectedIds = [...selectedIds];
      newSelectedIds.splice(newSelectedIds.indexOf(id), 1);
    }
    setSelectedIds(newSelectedIds);
  };

  useEffect(() => {
    setGlobalSelect(selectedIds.length === searchResults?.length);
    setGlobalIndeterminate(
      selectedIds.length !== 0 && selectedIds.length !== searchResults?.length
    );
  }, [selectedIds, searchResults]);

  const handleGlobalSelect = (_: any, checked: boolean) => {
    if (checked) {
      setSelectedIds(searchResults.map((item: any) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  useEffect(() => {
    const parsedFilters =
      filterParameter !== '' ? JSON.parse(atob(filterParameter)) : {};

    window.scrollTo(0, 0);

    getAccessTokenSilently().then((token: string) => {
      apiService
        .getSearchResults(
          searchString,
          parsedFilters,
          selectedSortOrder,
          {
            currentPage: page,
          },
          token
        )
        .then((result: any) => {
          setSearchResults(result.hits);
          setSearchFacets(result.facets);
          setPages(result.pagination.pages);
          setLoading(false);
        });
    });
  }, [
    searchString,
    getAccessTokenSilently,
    filterParameter,
    selectedSortOrder,
    page,
  ]);

  const handleOrderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    query.set('sort', event.target.value as string);

    history.push({
      pathname: history.location.pathname,
      search: query.toString(),
    });
    setSortOrder(event.target.value as string);
  };

  let content;
  if (isLoading) {
    content = <div data-testid="loading"></div>;
  } else {
    content = (
      <div className={styles.Search} data-testid="Search">
        <div className={styles.container}>
          <div className={styles.FacetsWrapper}>
            {searchResults?.length !== 0 ? (
              <>
                <FormControl variant="outlined">
                  <InputLabel id="search-sort-label">Sort by</InputLabel>
                  <Select
                    labelId="search-sort-label"
                    id="search-sort"
                    value={selectedSortOrder}
                    onChange={handleOrderChange}
                    label="Sort by">
                    <MenuItem value="score">Relevance</MenuItem>
                    <MenuItem value="new">Newest first</MenuItem>
                    <MenuItem value="old">Oldest first</MenuItem>
                    <MenuItem value="highSold">Highest Sold</MenuItem>
                    <MenuItem value="lowSold">Lowest Sold</MenuItem>
                  </Select>
                </FormControl>
                <SearchFacets facets={searchFacets}></SearchFacets>
                <FeatureToggle featureName="SAVE_SEARCH">
                  <SaveSearch />
                </FeatureToggle>
              </>
            ) : null}
          </div>
          <div className={styles.ResultsWrapper}>
            <div className={styles.ResultsControls}>
              <div className={styles.controlsSelectAction}>
                {searchResults?.length !== 0 ? (
                  <FeatureToggle featureName="SEARCH_ACTIONS">
                    <ActionSelect
                      selectedIds={selectedIds}
                      globalSelect={globalSelect}
                      indeterminate={globalIndeterminate}
                      onChange={handleGlobalSelect}></ActionSelect>
                  </FeatureToggle>
                ) : null}
              </div>
              <div className={styles.controlsButtonActions}>
                {searchResults?.length !== 0 ? (
                  <FeatureToggle featureName="DOWNLOAD">
                    <DownloadButton></DownloadButton>
                  </FeatureToggle>
                ) : null}
                <FeatureToggle featureName="NEW_FROM_SEARCH">
                  <NewEntryButton></NewEntryButton>
                </FeatureToggle>
              </div>
            </div>
            {searchResults?.length !== 0 ? (
              <>
                <SearchResults
                  results={searchResults}
                  selectedIds={selectedIds}
                  onSelectChanged={handleItemSelectChanged}
                />
                {pages > 1 ? (
                  <div className={styles.PaginationWrapper}>
                    <Pagination
                      page={page}
                      count={pages}
                      renderItem={(item) => {
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        if (item.page === 1) {
                          searchParams.delete('page');
                        } else {
                          searchParams.set('page', String(item.page));
                        }
                        return (
                          <PaginationItem
                            component={Link}
                            to={`${
                              location.pathname
                            }?${searchParams.toString()}`}
                            {...item}
                          />
                        );
                      }}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        {searchResults?.length === 0 ? <EmptyResults></EmptyResults> : null}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title data-testid="helmet-title">
          Lot Archive: {`${searchString}`}
        </title>
      </Helmet>
      {content}
    </>
  );
};

export default Search;

import { useAuth0 } from '@auth0/auth0-react';
import { IonIcon } from '@ionic/react';
import { Button, MenuItem } from '@material-ui/core';
import Dialog from '@reach/dialog';
import { closeCircleOutline, warning } from 'ionicons/icons';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import apiService from '../../../services/api/api.serivce';
import styles from './SingleDelete.module.scss';

const useDeleteCall = (id: string) => {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const execute = async () => {
    setResult(false);
    setError(false);
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    apiService
      .deleteEntry(id, accessToken)
      .then(() => {
        setLoading(false);
        setResult(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return { isLoading, execute, result, error };
};

const SingleDelete: React.FC<{ onSelected: any; data: any }> = ({
  onSelected,
  data,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showDialog, setShowDialog] = React.useState(false);
  const { isLoading, execute, result, error } = useDeleteCall(data.id);

  const open = () => {
    onSelected();
    setShowDialog(true);
  };
  const close = useCallback(() => {
    if (!isLoading) setShowDialog(false);
  }, [isLoading, setShowDialog]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Could not delete. Please try again', {
        variant: 'warning',
      });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (result) {
      enqueueSnackbar(
        'This entry was marked to be deleted. It might take a couple of days for your request to be completed',
        {
          variant: 'success',
        }
      );
      close();
    }
  }, [result, enqueueSnackbar, close]);

  return (
    <>
      <Dialog isOpen={showDialog} onDismiss={close}>
        <div className={styles.ModalHeader}>
          <h1 className={styles.ModalTitle}>
            <IonIcon icon={warning} ariaLabel="Close Modal" />
            Delete group
          </h1>
          <button className={styles.CloseButton} onClick={close}>
            <IonIcon icon={closeCircleOutline} ariaLabel="Close Modal" />
          </button>
        </div>
        <div className={styles.ModalContent}>
          Do you really want to delete this item?
        </div>
        <div className={styles.ModalActions}>
          <Button type="button" variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={execute}>
            {isLoading ? 'Loading ...' : 'Yes, delete this entry'}
          </Button>
        </div>
      </Dialog>

      <MenuItem onClick={open}>Delete</MenuItem>
    </>
  );
};

export default SingleDelete;

import React, { useEffect, useState } from 'react';
import styles from './FacetDateInput.module.scss';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface RangeData {
  min: number;
  max: number;
}

interface StateData {
  from?: Date | null;
  to?: Date | null;
}

interface InputData {
  from?: number;
  to?: number;
}

interface FacetInputProps {
  facetData: RangeData;
  facetKey: string;
  name: string;
  selectedData: InputData;
  onSelectDispatch: Function;
}

const FacetInput: React.FC<FacetInputProps> = (props: FacetInputProps) => {
  const [selectedValues, setSelectedValues] = useState<StateData>({
    from: props.selectedData?.from ? new Date(props.selectedData.from) : null,
    to: props.selectedData?.to ? new Date(props.selectedData.to) : null,
  });

  useEffect(() => {
    setSelectedValues({
      from: props.selectedData?.from ? new Date(props.selectedData.from) : null,
      to: props.selectedData?.to ? new Date(props.selectedData.to) : null,
    });
  }, [props.selectedData]);

  const dispatch = (data: StateData) => {
    props.onSelectDispatch({
      type: 'SET_FACET',
      facet: {
        key: props.facetKey,
        data,
      },
    });
  };

  const onChangeMin = (date: Date | null) => {
    dispatch({
      to: selectedValues.to,
      from: date,
    });
  };

  const onChangeMax = (date: Date | null) => {
    dispatch({
      from: selectedValues.from,
      to: date,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={styles.FacetInput} data-testid="FacetInput">
        <h3 className={styles.Title}>
          <div>{props.name}</div>
        </h3>

        <div className={styles.Inputs}>
          <DatePicker
            inputFormat="MM/dd/yyyy"
            label="From"
            minDate={new Date(props.facetData.min)}
            maxDate={new Date(props.facetData.max)}
            value={selectedValues?.from}
            onChange={onChangeMin}
            renderInput={(params) => (
              <TextField variant="outlined" {...params} />
            )}
          />
        </div>
        <div className={styles.Inputs}>
          <DatePicker
            inputFormat="MM/dd/yyyy"
            label="To"
            minDate={new Date(props.facetData.min)}
            maxDate={new Date(props.facetData.max)}
            value={selectedValues?.to}
            onChange={onChangeMax}
            renderInput={(params) => (
              <TextField variant="outlined" {...params} />
            )}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default FacetInput;

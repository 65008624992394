import { IonIcon } from '@ionic/react';
import { chevronForward, documentOutline } from 'ionicons/icons';
import React from 'react';
import nl2br from 'react-nl2br';
import styles from './DetailPageEntry.module.scss';
import DateFnsAdapter from '@date-io/date-fns';
import { Icon, IconIdentifier } from '../Icon/Icon';
import SingleActionMenu from '../DetailPageComponents/SingleActionMenu/SingleActionMenu';

const dateFns = new DateFnsAdapter();
const currencyFormatter = new Intl.NumberFormat('en-US', {});

const DetailPageEntry: React.FC<{ data: any; part_of_group: boolean }> = ({
  data,
  part_of_group,
}) => {
  const renderYear = (date: any) => {
    const from = new Date(date.from).getFullYear();
    return from;
  };

  const renderCountry = (content: any) => {
    if (content.categories) {
      return (
        <div className={styles.TableColumn}>
          <div className={styles.TableHeader}>Categories</div>
          <div>{content.categories.map((c: any) => c.name).join(' ')}</div>
          {content.orignalCategories ? (
            <div className={styles.OrgCategories}>
              {content.orignalCategories
                .filter((c: any) => content.categories.indexOf(c) === -1)
                .join(' ')}
            </div>
          ) : null}
        </div>
      );
    }
    return <div className={styles.TableColumn}></div>;
  };

  const renderCatalogs = (content: any) => {
    if (content.catalogs) {
      return (
        <div className={styles.TableColumn}>
          <div className={styles.TableHeader}>Catalogs</div>
          <div>
            {content.catalogNumbers
              ? content.catalogNumbers
                  .map((c: any) => c.type + ' ' + c.number)
                  .join(' ')
              : null}
          </div>
        </div>
      );
    }
    return <div className={styles.TableColumn}></div>;
  };

  const renderCondition = (content: any) => {
    if (content.conditions) {
      return (
        <div className={styles.TableColumnIcons}>
          <div className={styles.TableHeader}>Condition</div>
          <div>
            {content.conditions.map((icon: IconIdentifier) => (
              <Icon icon={icon}></Icon>
            ))}
          </div>
        </div>
      );
    }
    return <div className={styles.TableColumnIcons}></div>;
  };
  const renderTypes = (content: any) => {
    if (content.formats) {
      return (
        <div className={styles.TableColumnIcons}>
          <div className={styles.TableHeader}>Formats</div>
          <div>
            {content.formats.map((icon: IconIdentifier) => (
              <Icon icon={icon}></Icon>
            ))}
          </div>
        </div>
      );
    }
    return <div className={styles.TableColumnIcons}></div>;
  };
  const renderCheck = (content: any) => (
    <div className={styles.TableColumnIcons}></div>
  );
  const renderNote = (content: any) => {
    if (content.note) {
      return (
        <div className={styles.Note}>
          <h3 className={styles.Section}>Notes</h3>
          {nl2br(content.note)}
        </div>
      );
    }

    return null;
  };
  const renderPdfLink = (content: any) => {
    if (content.link_to_pdf) {
      return (
        <div className={styles.Link}>
          <a
            href={content.link_to_pdf}
            target="_blank"
            rel="noreferrer"
            className={styles.PdfLink}>
            <IonIcon icon={documentOutline} />
            PDF
          </a>
        </div>
      );
    }

    return null;
  };

  const renderDate = (date: any) => {
    const from = new Date(date.from);
    const to = new Date(date.to);
    return `${dateFns.format(from, 'MM/dd/yyyy')} - ${dateFns.format(
      to,
      'MM/dd/yyyy'
    )}`;
  };

  const renderTitle = (data: any) => {
    switch (data.type) {
      case 'exhibition':
      case 'publication':
        return data.title;
      case 'lot':
        return (
          <>
            {data.auctioneer}{' '}
            <IonIcon className={styles.headerSeparator} icon={chevronForward} />{' '}
            {data.auction}{' '}
            <IonIcon className={styles.headerSeparator} icon={chevronForward} />{' '}
            {data.lot_number}
          </>
        );
    }
  };

  const renderPrices = (content: any) => {
    let startingBid;
    let soldFor;

    startingBid = (
      <div className={styles.StartingBig}>
        Starting bid: {currencyFormatter.format(content.starting_price)}{' '}
        {content.currency}
      </div>
    );

    if (content.sold) {
      soldFor = (
        <div>
          {currencyFormatter.format(content.sold_for)} {content.currency}
        </div>
      );
    }

    return (
      <>
        {startingBid}
        {soldFor}
      </>
    );
  };

  const renderImage = (content: any) => {
    let mainImage;
    let preview;
    if (content.images?.length > 0) {
      mainImage = (
        <img
          src={`${process.env.REACT_APP_IMAGE_CDN_DOMAIN}/${content.auctioneerId}/${content.auctionId}/${content.images[0]}`}
          alt=""
          className={styles.mainImage}></img>
      );
    }

    if (content.images?.length > 1) {
      preview = (
        <div className={styles.previewImages}>
          {content.images.slice(1).map((url: string) => {
            return (
              <img
                src={`${process.env.REACT_APP_IMAGE_CDN_DOMAIN}/${content.auctioneerId}/${content.auctionId}/${url}`}
                alt=""
                className={styles.previewImage}></img>
            );
          })}
        </div>
      );
    }

    return (
      <>
        {mainImage}
        {preview}
      </>
    );
  };

  return (
    <div className={styles.DetailPageEntry} data-testid="DetailPageEntry">
      <div className={styles.Side}>
        <div className={styles.Year}>{renderYear(data.date)}</div>
        {data.cover_image ? (
          <img
            src={data.cover_image}
            alt=""
            className={styles.CoverImage}></img>
        ) : null}
        {renderPdfLink(data)}
      </div>
      <div className={styles.MainContent}>
        <div className={styles.Header}>
          <div>
            <h2>{renderTitle(data)}</h2>
            <h3 className={styles.fullDate}>{renderDate(data.date)}</h3>
          </div>
          <SingleActionMenu
            className={styles.ActionButton}
            entry={data}
            part_of_group={part_of_group}></SingleActionMenu>
        </div>
        <div className={styles.Content}>
          <p>{data.description}</p>
        </div>
        <div className={styles.Images}>{renderImage(data)}</div>
        {data.type === 'lot' ? (
          <>
            <div className={styles.Table}>
              {renderCountry(data)}
              {renderCatalogs(data)}
              {renderCondition(data)}
              {renderTypes(data)}
              {renderCheck(data)}
            </div>
            {renderNote(data)}
            <div className={styles.Prices}>{renderPrices(data)}</div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DetailPageEntry;

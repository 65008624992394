import { TextField } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './FacetInput.module.scss';

interface RangeData {
  min: number;
  max: number;
}

interface InputData {
  from?: number;
  to?: number;
}

interface FacetInputProps {
  facetData: RangeData;
  facetKey: string;
  name: string;
  selectedData: InputData;
  onSelectDispatch: Function;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {});

const FacetInput: React.FC<FacetInputProps> = (props: FacetInputProps) => {
  const [selectedValues, setSelectedValues] = useState<InputData>(
    props.selectedData || {
      from: undefined,
      to: undefined,
    }
  );

  useEffect(() => {
    setSelectedValues(
      props.selectedData || {
        from: undefined,
        to: undefined,
      }
    );
  }, [props.selectedData]);

  const dispatch = (data: InputData) => {
    props.onSelectDispatch({
      type: 'SET_FACET',
      facet: {
        key: props.facetKey,
        data,
      },
    });
  };

  const onChangeMin = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      to: selectedValues.to,
      from: event.target.value !== '' ? Number(event.target.value) : undefined,
    });
  };
  const onChangeMax = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      to: event.target.value !== '' ? Number(event.target.value) : undefined,
      from: selectedValues.from,
    });
  };

  return (
    <div className={styles.FacetInput} data-testid="FacetInput">
      <h3 className={styles.Title}>
        <div>{props.name}</div>
      </h3>

      <div className={styles.Inputs}>
        <TextField
          type="text"
          placeholder={currencyFormatter.format(props.facetData?.min)}
          value={selectedValues?.from || ''}
          onChange={onChangeMin}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className={styles.Separator}>-</div>
        <TextField
          type="text"
          placeholder={currencyFormatter.format(props.facetData?.max)}
          value={selectedValues?.to || ''}
          onChange={onChangeMax}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
};

export default FacetInput;

import React from 'react';
import styles from './Error.module.scss';
import { Helmet } from 'react-helmet';

const Error: React.FC = () => (
  <>
    <Helmet>
      <title>Not Found - Lot Archive</title>
    </Helmet>
    <div className={styles.Error} data-testid="Error"></div>
  </>
);

export default Error;

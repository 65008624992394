import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import EmptyResults from '../../components/EmptyResults/EmptyResults';
import SearchResults from '../../components/SearchResults/SearchResults';
import apiService from '../../services/api/api.serivce';
import styles from './DlrSearch.module.scss';

const DlrSearch: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState({} as any);

  const handleItemSelectChanged = (id: string, checked: boolean) => {};

  useEffect(() => {
    window.scrollTo(0, 0);

    getAccessTokenSilently().then((token: string) => {
      apiService.getDlrSearchResults(token).then((result: any) => {
        setSearchResults(result.hits);
        setLoading(false);
      });
    });
  }, [getAccessTokenSilently]);

  let content;
  if (isLoading) {
    content = <div data-testid="loading"></div>;
  } else {
    content = (
      <div className={styles.Search} data-testid="Search">
        <div className={styles.container}>
          <div className={styles.ResultsWrapper}>
            {searchResults?.length !== 0 ? (
              <>
                <SearchResults
                  results={searchResults}
                  selectedIds={[]}
                  onSelectChanged={handleItemSelectChanged}
                />
              </>
            ) : null}
          </div>
        </div>
        {searchResults?.length === 0 ? <EmptyResults></EmptyResults> : null}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title data-testid="helmet-title">
          Lot Archive - DLR Search Results
        </title>
      </Helmet>
      {content}
    </>
  );
};

export default DlrSearch;

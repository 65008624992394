import React from 'react';
import styles from './Main.module.scss';
import { Helmet } from 'react-helmet';
import { IonIcon } from '@ionic/react';
import { starOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import FeatureToggle from '../../components/FeatureToggle/FeatureToggle';

const Main: React.FC = () => (
  <>
    <Helmet>
      <title>Lot Archive</title>
    </Helmet>
    <div className={styles.Main} data-testid="Main">
      <div className={styles.container}>
        <FeatureToggle featureName="SAVED_SEARCHES">
          <h4>
            <IonIcon icon={starOutline}></IonIcon> Saved Searches:
          </h4>
          <div>
            <Link to="/search?q=mit&sort=lowSold&filters=eyJjb25kaXRpb25zIjpbIk1JTlQiXX0">
              Saved Search 2
            </Link>
          </div>
        </FeatureToggle>
      </div>
    </div>
  </>
);

export default Main;

import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './EmptyResults.module.scss';

const EmptyResults: React.FC = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  return (
    <div className={styles.EmptyResults} data-testid="EmptyResults">
      <div className={styles.IconWrapper}>
        <IonIcon icon={search}></IonIcon>
      </div>
      <h3>No matching entries</h3>
      <p>
        We could not find anything matching your search.
        <br /> Either change your search words or{' '}
        <button onClick={goBack} className={styles.backLink}>
          go back
        </button>{' '}
        to change the filters.
      </p>
    </div>
  );
};

export default EmptyResults;

export const deleteEntry = async (id: string, token: string): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/details/${id}`,
    {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
};

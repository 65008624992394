import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import FeatureToggle from '../../components/FeatureToggle/FeatureToggle';
import NewLotForm from '../../components/NewLotForm/NewLotForm';
import NewMiscForm from '../../components/NewMiscForm/NewMiscForm';
import styles from './NewView.module.scss';
import { Link as RouterLink } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';

const NewView: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const connectedTo = query.get('connectTo') || null;

  const [typeToCreate, setTypeToCreate] = useState<string | null>('lot');
  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setTypeToCreate(event.target.value as string);
  };
  return (
    <div className={styles.NewView}>
      <h1>{connectedTo ? 'Create a new provience' : 'Create a new entry'}</h1>
      {connectedTo ? (
        <div className={styles.Card}>
          <div className={styles.connectedTo}>
            <TextField
              className={styles.connectedToInput}
              value={connectedTo}
              disabled={true}
              name="connectedTo"
              label="For the entry with the id"
              variant="outlined"
            />
            <Button
              className={styles.connectedToButton}
              component={RouterLink}
              to={`/detail/${connectedTo}`}
              type="button">
              <IonIcon icon={openOutline}></IonIcon>
            </Button>
          </div>
        </div>
      ) : null}
      <FeatureToggle featureName="NEW_ENITTY_SELECT">
        <div className={styles.EntityType}>
          <FormControl variant="outlined" className={styles.EntityTypeSelect}>
            <InputLabel id="demo-simple-select-outlined-label">
              Entity type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={typeToCreate}
              onChange={handleChange}
              label="Entity type"
              placeholder="Please select the ttype you want to createt">
              <MenuItem value={'lot'}>Lot</MenuItem>
              <MenuItem value={'misc'}>Misc</MenuItem>
            </Select>
          </FormControl>
        </div>
      </FeatureToggle>
      {typeToCreate === 'lot' ? <NewLotForm /> : null}
      {typeToCreate === 'misc' ? <NewMiscForm /> : null}
    </div>
  );
};

export default NewView;

import { IonIcon } from '@ionic/react';
import { Button } from '@material-ui/core';
import { addCircleOutline, copyOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import DetailPageAggregation from '../../components/DetailPageAggregation/DetailPageAggregation';
import BatchEdit from '../../components/DetailPageComponents/BatchEdit/BatchEdit';
import DeleteGroup from '../../components/DetailPageComponents/DeleteGroup/DeleteGroup';
import DetailPageEntry from '../../components/DetailPageEntry/DetailPageEntry';
import DetailPageInfo from '../../components/DetailPageInfo/DetailPageInfo';
import FeatureToggle from '../../components/FeatureToggle/FeatureToggle';
import apiService from '../../services/api/api.serivce';
import styles from './DetailPageView.module.scss';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Connect from '../../components/DetailPageComponents/Connect/Connect';

interface DetailEntryItem {
  id: string;
}
interface Response {
  id: string;
  groupCount: number;
  tags: string[];
  items: DetailEntryItem[];
}
const transformResponse = (data: any) => {
  return {
    id: '___',
    groupCount: data.items.length,
    tags: [],
    items: data.items,
  };
};

const useLoadData = (id: string) => {
  const [data, setData] = useState<Response | null>(null);
  const [isLoading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const loadData = async (id: string) => {
      const accessToken = await getAccessTokenSilently();
      apiService.getDetailsNew(id, accessToken).then((result) => {
        setData(transformResponse(result));
        setLoading(false);
      });
    };
    loadData(id);
  }, [id, getAccessTokenSilently]);

  return { isLoading, data };
};

const DetailPageView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data } = useLoadData(id);

  let content;
  if (isLoading) {
    content = <div data-testid="loading"></div>;
  } else {
    content = (
      <div className={styles.DetailPageView} data-testid="DetailPageView">
        <div className={styles.container}>
          <div className={styles.main}>
            {data?.items
              .sort((a: any, b: any) => (a.date.from < b.date.from ? 1 : -1))
              .map((item: DetailEntryItem, index: number) => (
                <DetailPageEntry
                  data={item}
                  key={index}
                  part_of_group={data.items?.length !== 1}></DetailPageEntry>
              ))}
          </div>
          <div className={styles.sidebar}>
            <FeatureToggle featureName="DETAIL_COPY_PROVIENCES">
              <Button
                type="button"
                variant="contained"
                color="primary"
                startIcon={<IonIcon icon={copyOutline} />}>
                Copy Proviences
              </Button>
            </FeatureToggle>
            <FeatureToggle featureName="DETAIL_ADD_PROVIENCES">
              <Button
                component={RouterLink}
                to={`/new/?connectTo=${id}`}
                type="button"
                variant="outlined"
                startIcon={<IonIcon icon={addCircleOutline} />}>
                Add new provience
              </Button>
            </FeatureToggle>
            <Connect data={data}></Connect>
            <DetailPageAggregation data={data}></DetailPageAggregation>
            <DetailPageInfo data={data}></DetailPageInfo>
            <FeatureToggle featureName="DETAIL_BATCH_EDIT">
              <BatchEdit data={data}></BatchEdit>
            </FeatureToggle>
            <FeatureToggle featureName="DETAIL_DELETE_GROUP">
              <DeleteGroup data={data}></DeleteGroup>
            </FeatureToggle>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default DetailPageView;

import React, { useContext } from 'react';
import { FeatureToggleContext, FeatureToggleIdentifier } from './context';

type FeatureToggleProps = {
  featureName: FeatureToggleIdentifier;
  showOnlyWhenDisabled?: boolean;
};

export const FeatureToggle: React.FC<FeatureToggleProps> = ({
  featureName,
  showOnlyWhenDisabled = false,
  children,
}) => {
  const { features, featuresLoaded } = useContext(FeatureToggleContext);
  const toggleActive = features.indexOf(featureName) !== -1;
  const showContent =
    (toggleActive && !showOnlyWhenDisabled) ||
    (!toggleActive && showOnlyWhenDisabled);

  return featuresLoaded && showContent ? <>{children}</> : null;
};

export default FeatureToggle;

import { CircularProgress, TextField } from '@material-ui/core';
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import apiService from '../../services/api/api.serivce';

type Category = {
  id: number;
  name: string;
};

type Props = {
  label?: string;
  onChange?: (categoryIds: number[]) => void;
};

const CategoriesAutocomplete: React.FC<Props> = ({ label, onChange }) => {
  const [searchString, setSearchString] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Category[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    (async () => {
      const categories = await apiService.getCategories(searchString);

      if (active) {
        setOptions(categories);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, searchString]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleSearchChange = (event: any) => {
    setSearchString(event.target.value);
  };

  const handleSelected = (event: any, value: Category[]) => {
    if (onChange) {
      onChange(value.map((category) => category.id));
    }
  };

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleSelected}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) => option.name}
      options={options}
      loading={loading}
      filterSelectedOptions
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label || 'Categories'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            onChange: handleSearchChange,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default CategoriesAutocomplete;

import { useAuth0 } from '@auth0/auth0-react';
import { IonIcon } from '@ionic/react';
import { Button } from '@material-ui/core';
import Dialog from '@reach/dialog';
import { closeCircleOutline, trashBinOutline, warning } from 'ionicons/icons';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import apiService from '../../../services/api/api.serivce';
import styles from './DeleteGroup.module.scss';

interface Props {
  data: any;
}

const useDeleteCall = (id: string) => {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const execute = async () => {
    setResult(false);
    setError(false);
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    apiService
      .deleteGroup(id, accessToken)
      .then(() => {
        setLoading(false);
        setResult(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return { isLoading, execute, result, error };
};

const DeleteGroup: React.FC<Props> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [showDialog, setShowDialog] = React.useState(false);
  const { isLoading, execute, result, error } = useDeleteCall(data.items[0].id);

  const open = () => setShowDialog(true);
  const close = useCallback(() => {
    if (!isLoading) setShowDialog(false);
  }, [isLoading, setShowDialog]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Could not delete. Please try again', {
        variant: 'warning',
      });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (result) {
      enqueueSnackbar(
        'This group  was marked to be deleted. It might take a couple of days for your request to be completed',
        {
          variant: 'success',
        }
      );
      close();
      history.push('/');
    }
  }, [result, enqueueSnackbar, close, history]);

  return (
    <>
      <Button
        type="button"
        variant="outlined"
        onClick={open}
        startIcon={<IonIcon icon={trashBinOutline} />}>
        Delete
      </Button>

      <Dialog isOpen={showDialog} onDismiss={close}>
        <div className={styles.ModalHeader}>
          <h1 className={styles.ModalTitle}>
            <IonIcon icon={warning} ariaLabel="Close Modal" />
            Delete group
          </h1>
          <button className={styles.CloseButton} onClick={close}>
            <IonIcon icon={closeCircleOutline} ariaLabel="Close Modal" />
          </button>
        </div>
        <div className={styles.ModalContent}>
          Do you really want to delete the entire group?
          <br />
          This would remove <strong>all {data.items.length}</strong> items!
        </div>
        <div className={styles.ModalActions}>
          <Button type="button" variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={execute}>
            {isLoading ? 'Loading ...' : 'Yes, delete the entire group'}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteGroup;

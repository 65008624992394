import React from 'react';
import styles from './Topbar.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { logOutOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';

const Topbar: React.FC = () => {
  const { user, logout } = useAuth0();

  const onLogoutClick = (): void => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className={styles.Topbar} data-testid="Topbar">
      <div className={styles.container}>
        <div className={styles.name}>
          <Link className={styles.homeLink} to="/">
            Lot Archive
          </Link>
        </div>
        <div className={styles.userAvatar}>
          <img className={styles.avatar} src={user?.picture} alt="avatar"></img>
          <span className={styles.userName}>
            {user?.given_name || user?.nickname}
          </span>
          <button
            className={styles.logoutButton}
            aria-label="logout"
            onClick={onLogoutClick}>
            <IonIcon icon={logOutOutline} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Topbar;

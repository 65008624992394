import { IonIcon } from '@ionic/react';
import { Button, FormControlLabel, Switch, TextField } from '@material-ui/core';
import Dialog from '@reach/dialog';
import { closeCircleOutline, starOutline } from 'ionicons/icons';
import React, { ChangeEvent } from 'react';
import styles from './SaveSearch.module.scss';

const SaveSearch: React.FC = () => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageRecognition, setImageRecognition] = React.useState(false);
  const [name, setName] = React.useState('');

  const open = () => setShowDialog(true);
  const close = () => {
    if (!loading) setShowDialog(false);
  };

  const handleImageRecognitionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setImageRecognition(checked);
  };
  const saveSearch = () => {
    setLoading(true);
  };

  const updateName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <>
      <Button
        className={styles.MainButton}
        type="button"
        onClick={open}
        startIcon={<IonIcon icon={starOutline} />}>
        Save your Search
      </Button>

      <Dialog isOpen={showDialog} onDismiss={close}>
        <div className={styles.ModalHeader}>
          <h1 className={styles.ModalTitle}>Save your Search</h1>
          <button className={styles.CloseButton} onClick={close}>
            <IonIcon icon={closeCircleOutline} ariaLabel="Close Modal" />
          </button>
        </div>
        <div className={styles.ModalContent}>
          <p className={styles.textCopy}>
            You like to save this search for later? Just give it a name:
          </p>
          <br />
          <TextField
            className={styles.input}
            label="Name of the Search"
            type="text"
            placeholder="How so you like to name your search"
            value={name}
            onChange={updateName}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          <br />
          <FormControlLabel
            control={
              <Switch
                disabled={loading}
                checked={imageRecognition}
                onChange={handleImageRecognitionChange}
                name="imageRecognition"
                color="primary"
              />
            }
            label="Enable image recognition for new import matching this search"
          />
        </div>
        <div className={styles.ModalActions}>
          <Button type="button" variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            type="button"
            disabled={loading || name === ''}
            variant="contained"
            color="primary"
            onClick={saveSearch}>
            {loading ? 'Loading ...' : 'Save'}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default SaveSearch;

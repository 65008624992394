import { IonIcon } from '@ionic/react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { ellipsisVertical } from 'ionicons/icons';
import React from 'react';
import FeatureToggle from '../../FeatureToggle/FeatureToggle';
import SingleDelete from '../SingleDelete/SingleDelete';
import Ungroup from '../Ungroup/Ungroup';

const SingleActionMenu: React.FC<{
  className?: string;
  entry: any;
  part_of_group: boolean;
}> = ({ className, entry, part_of_group }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <Ungroup
          onSelected={handleMenuClose}
          data={entry}
          disabled={!part_of_group}></Ungroup>
        <FeatureToggle featureName="DETAIL_ENTRY_EDIT">
          <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
        </FeatureToggle>
        <FeatureToggle featureName="DETAIL_ENTRY_NOTE">
          <MenuItem onClick={handleMenuClose}>Add/Edit Note</MenuItem>
        </FeatureToggle>
        <SingleDelete onSelected={handleMenuClose} data={entry}></SingleDelete>
      </Menu>
      <IconButton
        className={className}
        aria-label="options"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}>
        <IonIcon icon={ellipsisVertical}></IonIcon>
      </IconButton>
    </>
  );
};

export default SingleActionMenu;

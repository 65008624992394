const transformFromEsToDetailPage = (data: any) => {
  return {
    id: data._id,
    groupCount: 1,
    tags: data._source.tags || [],
    soldFor: {
      currency: data._source.currency[0],
      from: data._source.soldFor_min,
      to: data._source.soldFor_max,
    },
    imageRecognition: data._source.imageRecognition,
    items: [
      {
        id: data._id,
        type: 'lot',
        images: data._source.images,
        auctioneer: data._source.auctioneerName[0],
        auctioneerId: data._source.auctioneerId,
        auction: data._source.auctionName[0],
        auctionId: data._source.auctionId,
        lot_number: data._source.lotNumber[0],
        description: data._source.description[0],
        date: {
          from: data._source.date_range.gte,
          to: data._source.date_range.lte,
        },
        starting_price: data._source.startingPrice_min,
        sold_for: data._source.soldFor_min,
        categories: data._source.categories,
        orignalCategories: data._source.orignalCategories,
        conditions: data._source.conditions,
        destinations: data._source.destinations,
        catalogs: data._source.catalogs,
        catalogNumbers: data._source.catalogNumbers,
        formats: data._source.formats,
        currency: data._source.currency[0],
        sold: data._source.sold,
      },
    ],
  };
};
export const getDetails = async (id: string): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_ELASTIC_DOMAIN}/_doc/${id}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return transformFromEsToDetailPage(await response.json());
};
export const getDetailsNew = async (
  id: string,
  token: string
): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/details/${id}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
};

import React from 'react';
import styles from './App.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import Topbar from './components/Topbar/Topbar';
import Hero from './components/Hero/Hero';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import Footer from './components/Footer/Footer';
import { CircularProgress } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect({
      appState: {
        redirect: window.location.pathname,
      },
    });
  }

  if (isAuthenticated) {
    return (
      <Router>
        <SnackbarProvider maxSnack={3}>
          <Topbar />
          <Hero />
          <Routes />
          <Footer />
        </SnackbarProvider>
      </Router>
    );
  } else {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }
}

export default App;

import { Button, TextField } from '@material-ui/core';
import React from 'react';
import FeatureToggle from '../FeatureToggle/FeatureToggle';
import styles from './NewMiscForm.module.scss';

const NewMiscForm: React.FC = () => (
  <>
    <div className={styles.Inputs}>
      <TextField id="outlined-basic" label="Title" variant="outlined" />
      <TextField id="outlined-basic" label="Date Range" variant="outlined" />
      <TextField
        id="outlined-basic"
        label="Description"
        multiline
        rows={4}
        variant="outlined"
      />
    </div>
    <FeatureToggle featureName="NEW_FILE_UPLOAD">
      <div className={styles.Card}>File Uploads</div>
    </FeatureToggle>
    <div className={styles.Actions}>
      <Button variant="contained" color="primary">
        Create new Entry
      </Button>
    </div>
  </>
);

export default NewMiscForm;

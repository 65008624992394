import React from 'react';

export type FeatureToggleIdentifier =
  | 'DOWNLOAD'
  | 'NEW_FROM_SEARCH'
  | 'SAVE_SEARCH'
  | 'SEARCH_ACTIONS'
  | 'SEARCH_ACTION_IMAGE_RECOGNITION'
  | 'SEARCH_ACTION_DELETE'
  | 'SEARCH_ACTION_BATCH_EDIT'
  | 'SEARCH_ACTION_COMBINE_TO_GROUP'
  | 'SAVED_SEARCHES'
  | 'DETAIL_CHANGE_IMAGE_RECOGNITION'
  | 'DETAIL_BATCH_EDIT'
  | 'DETAIL_DELETE_GROUP'
  | 'DETAIL_COPY_PROVIENCES'
  | 'DETAIL_CONNECT'
  | 'DETAIL_ADD_PROVIENCES'
  | 'DETAIL_ENTRY_UNGROUP'
  | 'DETAIL_ENTRY_EDIT'
  | 'DETAIL_ENTRY_NOTE'
  | 'DISCONNECT_KEEP_TAGS'
  | 'NEW_ENITTY_SELECT'
  | 'NEW_CREATE_AUCTION'
  | 'NEW_FILE_UPLOAD'
  | 'CUSTOM_DEFAULT_SORT';

type ContextType = {
  features: FeatureToggleIdentifier[];
  featuresLoaded: boolean;
};
export const FeatureToggleContext = React.createContext<ContextType>({
  features: [],
  featuresLoaded: false,
});

import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import Icon, { FormatIconIdentifier, FormatIcons } from '../Icon/Icon';
import styles from './FormatSelect.module.scss';

const FormatSelect: React.FC<{
  formats: FormatIconIdentifier[];
  onChange: any;
}> = ({ formats = [], onChange }) => {
  const handleChange = (event: any, checked: boolean) => {
    let newSelection = [...formats];
    if (checked) {
      newSelection.push(event.target.name);
    } else {
      newSelection.splice(newSelection.indexOf(event.target.name), 1);
    }
    onChange(newSelection);
  };

  return (
    <div className={styles.FormatSelect} data-testid="FormatSelect">
      {Object.keys(FormatIcons).map((icon) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={formats.includes(icon as FormatIconIdentifier)}
              onChange={handleChange}
              name={icon}
              color="primary"
            />
          }
          labelPlacement="top"
          label={<Icon icon={icon as FormatIconIdentifier}></Icon>}
        />
      ))}
    </div>
  );
};

export default FormatSelect;

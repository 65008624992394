export const getCategories = async (searchString?: string): Promise<any> => {
  return await Promise.resolve([
    {
      id: 1,
      name: 'Baden -> Baden',
    },
    {
      id: 2,
      name: 'Baden -> Landpost',
    },
  ]);
  // const response = await fetch(`/categories?q=${searchString}`, {
  //   mode: 'cors',
  //   cache: 'no-cache',
  //   credentials: 'same-origin',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   redirect: 'follow',
  //   referrerPolicy: 'no-referrer'
  // });

  // return await response.json();
};

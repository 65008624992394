export const connectGroup = async (
  groupIds: string[],
  token: string
): Promise<any> => {
  const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/connect/`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ ids: groupIds }),
  });

  if (response.status === 202) {
    return;
  } else {
    throw Error('could not connect');
  }
};

import { IonIcon } from '@ionic/react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { trashBinSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import styles from './CatalogEdit.module.scss';

export type Catalog = {
  type: string;
  number: string;
};
type Props = {
  data?: Catalog[];
  onChange?: (data: Catalog[]) => void;
};

const availableCatalogTypes = [
  'Michel',
  'Scott',
  'Yvert',
  'Maury',
  'Dallay',
  'Stanley Gibbons',
  'Chan',
  'SBK',
  'Sieger',
];

const CatalogEdit: React.FC<Props> = ({ data, onChange }) => {
  const [entries, setEntries] = useState<Catalog[]>(data || []);

  useEffect(() => {
    if (onChange)
      onChange(entries.filter((entry) => entry.type && entry.number));
  }, [entries, onChange]);

  const addNew = () => {
    setEntries([...entries, { type: '', number: '' }]);
  };

  const removeEntry = (index: number) => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
  };

  const updateNumber = (event: any) => {
    const index = event.target.name;
    const newEntries = [...entries];
    newEntries[index].number = event.target.value;
    setEntries(newEntries);
  };

  const handleTypeChange = (event: any) => {
    const index = event.target.name;
    const newEntries = [...entries];
    newEntries[index].type = event.target.value;
    setEntries(newEntries);
  };

  return (
    <div className={styles.CatalogEdit} data-testid="CatalogEdit">
      {entries.map((entry, index) => {
        return (
          <div key={index} className={styles.catalogRow}>
            <FormControl variant="outlined" className={styles.typeInput}>
              <InputLabel id={`catalogType-${index}`}>Type</InputLabel>
              <Select
                name={`${index}`}
                labelId={`catalogType-${index}`}
                value={entry.type}
                onChange={handleTypeChange}
                label="Type">
                {availableCatalogTypes.map((name) => (
                  <MenuItem value={name}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className={styles.numberInput}
              onChange={updateNumber}
              name={`${index}`}
              label="Catalog number"
              variant="outlined"
              value={entry.number}
            />
            <Button onClick={() => removeEntry(index)}>
              <IonIcon icon={trashBinSharp}></IonIcon>
            </Button>
          </div>
        );
      })}
      <Button onClick={addNew}>Add new Catalog</Button>
    </div>
  );
};

export default CatalogEdit;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from './views/Main/Main';
import Error from './views/Error/Error';
import Search from './views/Search/Search';
import DetailPageView from './views/DetailPageView/DetailPageView';
import NewView from './views/NewView/NewView';
import DlrSearch from './views/DlrSearch/DlrSearch';

export const Routes = () => {
  return (
    <Switch>
      <Route path="/search" children={<Search />}></Route>
      <Route path="/dlrSearch" children={<DlrSearch />}></Route>
      <Route path="/detail/:id" children={<DetailPageView />}></Route>
      <Route exact path="/new" children={<NewView />}></Route>
      <Route path="/new/:id" children={<NewView />}></Route>
      <Route exact path="/" children={<Main />}></Route>
      <Route path="*" children={<Error />}></Route>
    </Switch>
  );
};

import { Button, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import apiService from '../../services/api/api.serivce';
import AuctionAutocomplete from '../AuctionAutocomplete/AuctionAutocomplete';
import CategoriesAutocomplete from '../CatagoriesAutocomplete/CategoriesAutocomplete';
import CatalogEdit, { Catalog } from '../CatalogEdit/CatalogEdit';
import FeatureToggle from '../FeatureToggle/FeatureToggle';
import { ConditionIconIdentifier, FormatIconIdentifier } from '../Icon/Icon';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styles from './NewLotForm.module.scss';
import ConditionSelect from '../ConditionSelect/ConditionSelect';
import FormatSelect from '../FormatSelect/FormatSelect';

const reducer = (state: any, action: any) => {
  return {
    ...state,
    ...action.update,
  };
};
const NewLotForm: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const connectTo = query.get('connectTo') || undefined;
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [sold, setSold] = React.useState(false);
  const [state, dispatch] = useReducer(reducer, {});

  const saveLot = () => {
    setLoading(true);
    Promise.resolve();
    apiService
      .storeNewLot({
        ...state,
        sold,
        connectTo,
      })
      .then(() => {
        const { id } = { id: 'test-1' };
        enqueueSnackbar('Entry stored', {
          variant: 'success',
          action: () => (
            <Button component={RouterLink} to={`/detail/${id}`}>
              View
            </Button>
          ),
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar('Something went wrong. Please try again', {
          variant: 'warning',
        });
      });
  };

  const handleAuctionSelect = useCallback((auctionId: number | null) => {
    dispatch({
      update: {
        auctionId,
      },
    });
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const key = event?.target.name;
      const newData = {} as any;
      newData[key] = event?.target.value;
      dispatch({
        update: newData,
      });
    },
    []
  );

  const handleCategoriesChange = useCallback((categoryIds: number[]) => {
    dispatch({
      update: {
        categories: categoryIds,
      },
    });
  }, []);

  const handleDestinationChange = useCallback((categoryIds: number[]) => {
    dispatch({
      update: {
        destinations: categoryIds,
      },
    });
  }, []);

  const handleCatalogChange = useCallback((catalogs: Catalog[]) => {
    dispatch({
      update: {
        catalogs,
      },
    });
  }, []);

  const handleConditions = useCallback(
    (conditions: ConditionIconIdentifier[]) => {
      dispatch({
        update: {
          conditions,
        },
      });
    },
    []
  );

  const handleFormats = useCallback((formats: FormatIconIdentifier[]) => {
    dispatch({
      update: {
        formats,
      },
    });
  }, []);

  return (
    <>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Auction</div>
        <AuctionAutocomplete onChange={handleAuctionSelect} />
        <FeatureToggle featureName="NEW_CREATE_AUCTION">
          <p>or create new</p>
          <TextField
            id="outlined-basic"
            label="Auctionneer"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            label="Auction No"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            label="Auction Name"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            label="Date Range"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            label="Catalog Part Name"
            variant="outlined"
          />
        </FeatureToggle>
      </div>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Details</div>
        <TextField
          name="lotNumber"
          id="outlined-basic"
          label="Lot No"
          variant="outlined"
          required={true}
          onChange={handleInputChange}
        />
        <TextField
          name="description"
          required={true}
          id="outlined-basic"
          label="Description"
          multiline
          rows={4}
          variant="outlined"
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Categories</div>
        <CategoriesAutocomplete onChange={handleCategoriesChange} />
      </div>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Destinations</div>
        <CategoriesAutocomplete
          label="Destinations"
          onChange={handleDestinationChange}
        />
      </div>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Catalogs</div>
        <CatalogEdit data={state.catalogs} onChange={handleCatalogChange} />
      </div>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Conditions</div>
        <div className={styles.IconInputs}>
          <ConditionSelect
            conditions={state.conditions}
            onChange={handleConditions}
          />
        </div>
      </div>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Formats</div>
        <div className={styles.IconInputs}>
          <FormatSelect formats={state.formats} onChange={handleFormats} />
        </div>
      </div>
      <div className={styles.Inputs}>
        <div className={styles.sectionTitle}>Pricing</div>
        <div className={styles.CurrencyInfo}>
          <TextField
            className={styles.BidInput}
            required={true}
            id="outlined-basic"
            label="Starting price"
            variant="outlined"
            name="startingPrice"
            onChange={handleInputChange}
          />
          <TextField
            className={styles.CurrencyInput}
            id="outlined-basic"
            label="Currency"
            variant="outlined"
            name="currency"
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.CurrencyInfo}>
          <FormControlLabel
            className={styles.soldToggle}
            control={
              <Switch
                checked={sold}
                onChange={(_event: any, checked) => {
                  setSold(checked);
                }}
                color="primary"
              />
            }
            label="sold"
          />
          {sold ? (
            <TextField
              className={styles.BidInput}
              id="outlined-basic"
              label="Sold price"
              variant="outlined"
              name="soldFor"
              onChange={handleInputChange}
            />
          ) : null}
        </div>
      </div>
      <FeatureToggle featureName="NEW_FILE_UPLOAD">
        <div className={styles.Card}>File Uploads</div>
      </FeatureToggle>
      <div className={styles.Actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={saveLot}
          disabled={loading}>
          {loading ? 'Loading...' : 'Create new Entry'}
        </Button>
      </div>
      <Helmet>
        <title data-testid="helmet-title">Create a new Lot</title>
      </Helmet>
    </>
  );
};

export default NewLotForm;

import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import FeatureToggle from '../FeatureToggle/FeatureToggle';

const ActionSelect: React.FC<{
  selectedIds: string[];
  globalSelect: boolean;
  indeterminate: boolean;
  onChange: any;
}> = ({ selectedIds, globalSelect, indeterminate, onChange }) => {
  return (
    <>
      <Checkbox
        checked={globalSelect}
        indeterminate={indeterminate}
        color="primary"
        onChange={onChange}></Checkbox>
      {globalSelect || indeterminate ? (
        <FormControl variant="outlined">
          <InputLabel id="search-sort-label">Actions</InputLabel>
          <Select
            displayEmpty
            value={null}
            labelId="search-sort-label"
            id="search-sort"
            label="Select an Action">
            <FeatureToggle featureName="SEARCH_ACTION_IMAGE_RECOGNITION">
              <MenuItem>Mark for Image Recognition</MenuItem>
            </FeatureToggle>
            <FeatureToggle featureName="SEARCH_ACTION_DELETE">
              <MenuItem>Delete</MenuItem>
            </FeatureToggle>
            <FeatureToggle featureName="SEARCH_ACTION_BATCH_EDIT">
              <MenuItem>Batch Edit</MenuItem>
            </FeatureToggle>
            <FeatureToggle featureName="SEARCH_ACTION_COMBINE_TO_GROUP">
              <MenuItem>Combine into one Group</MenuItem>
            </FeatureToggle>
          </Select>
        </FormControl>
      ) : null}
    </>
  );
};

export default ActionSelect;

import React, { ChangeEvent, useState } from 'react';
import styles from './FacetList.module.scss';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import Icon, { IconIdentifier } from '../Icon/Icon';

interface BucketProps {
  key: string;
  doc_count: number;
}

interface FacetListProps {
  facetData: BucketProps[];
  facetKey: string;
  name: string;
  selectedData: string[];
  onSelectDispatch: Function;
  type?: 'icons' | 'bool' | 'list';
}

const COLLAPSED_SHOW_COUNT = 3;

const FacetList: React.FC<FacetListProps> = (props: FacetListProps) => {
  const renderingType = props.type || 'list';
  const [isOpen, setOpen] = useState(false);
  // const [selectedKeys, setselectedKeys] = useState(props.selectedData);
  const selectedKeys = props.selectedData || [];

  const onChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const keys = [...selectedKeys];
    if (!checked) {
      const index = keys.indexOf(event.target.id);
      if (index > -1) {
        keys.splice(index, 1);
      }
    } else {
      keys.push(event.target.id);
    }

    if (keys.length) {
      props.onSelectDispatch({
        type: 'SET_FACET',
        facet: {
          key: props.facetKey,
          data: keys,
        },
      });
    } else {
      props.onSelectDispatch({
        type: 'RESET_FACET',
        facet: {
          key: props.facetKey,
        },
      });
    }
  };

  const renderLabel = (bucket: BucketProps) => {
    let label;
    if (renderingType === 'icons') {
      label = (
        <span>
          <Icon
            icon={bucket.key as IconIdentifier}
            className={styles.IconLabel}></Icon>
        </span>
      );
    } else if (renderingType === 'bool') {
      label = <span>{bucket.key === '1' ? 'Yes' : 'No'}</span>;
    } else {
      label = <span>{bucket.key}</span>;
    }
    return (
      <>
        {label}
        <span className={styles.itemCount}>{bucket.doc_count}</span>
      </>
    );
  };

  const renderListItem = () => {
    let buckets = props.facetData;
    if (renderingType === 'list' && !isOpen) {
      buckets = buckets.slice(0, COLLAPSED_SHOW_COUNT);
    } else if (renderingType === 'bool') {
      buckets = [...buckets].sort((bucket1) => {
        return bucket1.key === '1' ? -1 : 1;
      });
    }

    return buckets.map((bucket, index: number) => {
      return (
        <FormControlLabel
          key={String(index)}
          control={
            <Checkbox
              id={bucket.key}
              color="primary"
              checked={selectedKeys?.includes(bucket.key)}
              onChange={onChange}
            />
          }
          label={renderLabel(bucket)}
        />
      );
    });
  };

  const renderToggleButton = () => {
    if (
      renderingType === 'list' &&
      props.facetData.length > COLLAPSED_SHOW_COUNT
    ) {
      return (
        <button onClick={toggleButton} className={styles.toggleButton}>
          {isOpen ? 'Collapse' : 'Show all'}
        </button>
      );
    }
  };

  const toggleButton = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={styles.FacetList} data-testid="FacetList">
      <h3 className={styles.Title}>
        <div>
          {props.name} ({props.facetData.length})
        </div>
        {renderToggleButton()}
      </h3>

      {renderListItem()}
      <div className={styles.footerButton}>
        {isOpen ? renderToggleButton() : ''}
      </div>
    </div>
  );
};
export default FacetList;

import { IonIcon } from '@ionic/react';
import { Button } from '@material-ui/core';
import { addCircleOutline } from 'ionicons/icons';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

const NewEntryButton: React.FC = () => {
  return (
    <Button
      component={RouterLink}
      to="/new"
      type="button"
      variant="contained"
      color="primary"
      startIcon={<IonIcon icon={addCircleOutline} />}>
      Create New
    </Button>
  );
};

export default NewEntryButton;

import { IonIcon } from '@ionic/react';
import { Checkbox } from '@material-ui/core';
import { chevronForward } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatureToggle from '../FeatureToggle/FeatureToggle';
import Icon, { IconIdentifier } from '../Icon/Icon';
import styles from './SearchItem.module.scss';

const currencyFormatter = new Intl.NumberFormat('en-US', {});

const SearchItem: React.FC<{
  content: any;
  selected?: boolean;
  onSelectChanged: any;
}> = (props: any) => {
  const renderCountry = (content: any) => {
    if (content.categories) {
      return (
        <div className={styles.TableColumn}>
          <div className={styles.TableHeader}>Categories</div>
          <div>{content.categories.join(' ')}</div>
          {content.orignalCategories ? (
            <div className={styles.OrgCategories}>
              {content.orignalCategories
                .filter((c: any) => content.categories.indexOf(c) === -1)
                .join(' ')}
            </div>
          ) : null}
        </div>
      );
    }
    return <div className={styles.TableColumn}></div>;
  };

  const renderCatalogs = (content: any) => {
    if (content.catalogs) {
      return (
        <div className={styles.TableColumn}>
          <div className={styles.TableHeader}>Catalogs</div>
          <div>
            {content.catalogNumbers
              ? content.catalogNumbers
                  .map((c: any) => c.type + ' ' + c.number)
                  .join(' ')
              : null}
          </div>
        </div>
      );
    }
    return <div className={styles.TableColumn}></div>;
  };

  const renderCondition = (content: any) => {
    if (content.conditions) {
      return (
        <div className={styles.TableColumn}>
          <div className={styles.TableHeader}>Condition</div>
          <div>
            {content.conditions.map((icon: IconIdentifier) => (
              <Icon icon={icon}></Icon>
            ))}
          </div>
        </div>
      );
    }
    return <div className={styles.TableColumn}></div>;
  };
  const renderTypes = (content: any) => {
    if (content.formats) {
      return (
        <div className={styles.TableColumn}>
          <div className={styles.TableHeader}>Formats</div>
          <div>
            {content.formats.map((icon: IconIdentifier) => (
              <Icon icon={icon}></Icon>
            ))}
          </div>
        </div>
      );
    }
    return <div className={styles.TableColumn}></div>;
  };
  const renderCheck = (content: any) => <></>;

  const renderPrices = (content: any) => {
    let startingBid;
    let soldFor;

    if (content.starting_price.from === content.starting_price.to) {
      startingBid = (
        <div className={styles.StartingBig}>
          Starting bid: {currencyFormatter.format(content.starting_price.from)}{' '}
          EUR
        </div>
      );
    } else {
      startingBid = (
        <div className={styles.StartingBig}>
          Starting bid: {currencyFormatter.format(content.starting_price.from)}{' '}
          - {currencyFormatter.format(content.starting_price.to)} EUR
        </div>
      );
    }

    if (content.sold) {
      if (content.sold_for.from === content.sold_for.to) {
        soldFor = (
          <div>{currencyFormatter.format(content.sold_for.from)} EUR</div>
        );
      } else {
        soldFor = (
          <div>
            {currencyFormatter.format(content.sold_for.from)} -{' '}
            {currencyFormatter.format(content.sold_for.to)} EUR
          </div>
        );
      }
    }

    return (
      <>
        {startingBid}
        {soldFor}
      </>
    );
  };

  const renderDateRange = (content: any) => {
    const from = new Date(content.date.from).getFullYear();
    if (!content.date.to || from === new Date(content.date.to).getFullYear()) {
      return from;
    } else {
      return from + ' - ' + new Date(content.date.to).getFullYear();
    }
  };

  const renderImage = (content: any) => {
    if (content.image) {
      const alt = `${content.auctioneer} > ${content.auction} > ${content.lot_number}`;
      return (
        <img
          src={`${process.env.REACT_APP_IMAGE_CDN_DOMAIN}/${content.auctioneerId}/${content.auctionId}/${content.image}`}
          alt={alt}></img>
      );
    }
  };

  const [checked, setChecked] = useState(props.selected);
  const changeSelected = (_: any, checked: boolean) => {
    setChecked(checked);
    props.onSelectChanged(props.content.id, checked);
  };

  useEffect(() => {
    setChecked(props.selected);
  }, [props.selected]);

  return (
    <div className={styles.SearchItem} data-testid="SearchItem">
      <div className={styles.Side}>
        <FeatureToggle featureName="SEARCH_ACTIONS">
          <Checkbox
            color="primary"
            checked={checked}
            onChange={changeSelected}></Checkbox>
        </FeatureToggle>
        <FeatureToggle featureName="SEARCH_ACTIONS" showOnlyWhenDisabled={true}>
          <div style={{ height: '42px' }}></div>
        </FeatureToggle>
        <div className={styles.Year}>{renderDateRange(props.content)}</div>
      </div>
      <div className={styles.MainContent}>
        <div className={styles.Header}>
          <h2>
            <Link to={`/detail/${props.content.id}`}>
              {props.content.auctioneer}{' '}
              <IonIcon
                className={styles.headerSeparator}
                icon={chevronForward}
              />{' '}
              {props.content.auction}
              <IonIcon
                className={styles.headerSeparator}
                icon={chevronForward}
              />{' '}
              {props.content.lot_number}
            </Link>
          </h2>
          <h3 className={styles.fullDate}>
            {props.content.groupCount && props.content.groupCount !== 1
              ? `Seen ${props.content.groupCount}x times`
              : ''}
          </h3>
        </div>
        <div className={styles.Content}>
          <p>{props.content.description}</p>
        </div>
        <div className={styles.Images}>{renderImage(props.content)}</div>
        <div className={styles.Table}>
          {renderCountry(props.content)}
          {renderCatalogs(props.content)}
          {renderCondition(props.content)}
          {renderTypes(props.content)}
          {renderCheck(props.content)}
        </div>
        <div className={styles.Prices}>{renderPrices(props.content)}</div>
      </div>
    </div>
  );
};

export default SearchItem;

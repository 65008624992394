interface esRange {
  gte: string | number;
  lte: string | number;
}

interface catalogNumbers {
  type: string;
  number: string;
}
interface esHitData {
  groupCount: number;
  auctionName: string[];
  auctionId: string;
  auctioneerName: string[];
  auctioneerId: string;
  date_range: esRange;
  lotNumber: string[];
  description: string[];
  conditions: string[];
  image: string;
  images: string[];
  categories: string[];
  orignalCategories: string[];
  destinations: string[];
  catalogs: string[];
  catalogNumbers: catalogNumbers[];
  catalogParts: string[];
  formats: string[];
  startingPrice_range: esRange;
  soldFor_range: esRange;
  currency: string[];
  imageRecognition: boolean;
  sold: boolean;
}

interface esHit {
  _id: string;
  _source: esHitData;
}

const transfromBooleans = (buckets: { doc_count: number; key: number }[]) => {
  return buckets.map((bucket) => ({
    doc_count: bucket.doc_count,
    key: String(bucket.key),
  }));
};

const transformFacetsFromEs = (aggregations: any) => {
  const facetData: any = {
    date: {
      min: null,
      max: null,
    },
    starting_bid: {
      min: null,
      max: null,
    },
    sold_for: {
      min: null,
      max: null,
    },
  };
  Object.keys(aggregations).forEach((aggregationKey) => {
    switch (aggregationKey) {
      case 'sold':
      case 'imageRecognition':
        facetData[aggregationKey] = transfromBooleans(
          aggregations[aggregationKey].buckets
        );
        break;
      case 'auctioneer':
      case 'auction':
      case 'catalogParts':
      case 'categories':
      case 'catalogs':
      case 'conditions':
      case 'formats':
      case 'check':
      case 'currency':
        const data = [...aggregations[aggregationKey].buckets];
        data.sort((a: any, b: any) => (a.key < b.key ? -1 : 1));
        facetData[aggregationKey] = data;
        break;
      case 'date_min':
        facetData.date.min = aggregations[aggregationKey].min;
        break;
      case 'date_max':
        facetData.date.max = aggregations[aggregationKey].max;
        break;
      case 'startingPrice_min':
        facetData.starting_bid.min = aggregations[aggregationKey].min;
        break;
      case 'startingPrice_max':
        facetData.starting_bid.max = aggregations[aggregationKey].max;
        break;
      case 'soldFor_min':
        facetData.sold_for.min = aggregations[aggregationKey].min;
        break;
      case 'soldFor_max':
        facetData.sold_for.max = aggregations[aggregationKey].max;
    }
  });
  return facetData;
};
const transformResultsFromEs = (hits: esHit[]) => {
  return hits.map((hit: esHit) => {
    const source = hit._source;
    let sold_for = {};

    if (source.sold) {
      sold_for = {
        from: source.soldFor_range.gte,
        to: source.soldFor_range.lte,
      };
    }

    return {
      id: hit._id,
      groupCount: source.groupCount,
      image: source.image,
      auctioneer: source.auctioneerName[0],
      auctioneerId: source.auctioneerId,
      auction: source.auctionName[0],
      auctionId: source.auctionId,
      lot_number: source.lotNumber[0],
      description: source.description[0],
      date: {
        from: source.date_range.gte,
        to: source.date_range.lte,
      },
      starting_price: {
        from: source.startingPrice_range.gte,
        to: source.startingPrice_range.lte,
      },
      sold_for,
      categories: source.categories,
      orignalCategories: source.orignalCategories,
      conditions: source.conditions,
      destinations: source.destinations,
      catalogs: source.catalogs,
      catalogNumbers: source.catalogNumbers,
      formats: source.formats,
      currency: source.currency,
      imageRecognition: source.imageRecognition,
      sold: source.sold,
    };
  });
};

export const transformFromEs = (
  esResponse: any,
  pagination: { currentPage: number; size: number }
) => {
  return {
    pagination: {
      currentPage: pagination.currentPage,
      total: esResponse.hits.total.value,
      pages: Math.ceil(esResponse.hits.total.value / pagination.size),
    },
    facets: transformFacetsFromEs(esResponse.aggregations),
    hits: transformResultsFromEs(esResponse.hits.hits),
  };
};

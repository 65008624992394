import { transformFromEs } from './helpers';
import { deleteGroup } from './apis/deleteGroup';
import { deleteEntry } from './apis/deleteEntry';
import { unGroupEntry } from './apis/unGroupEntry';
import { storeNewLot } from './apis/storeNewLot';
import { getDetails, getDetailsNew } from './apis/getDetails';
import { getAuctions } from './apis/getAuctions';
import { getCategories } from './apis/getCategories';
import { connectGroup } from './apis/connectGroup';
import { changeImageRecognition } from './apis/changeImageRecognition';

export const RESULT_COUNT = 20;

const generateFilterFromFacets = (facets: any) => {
  const filters = [];
  if (facets.auctioneer) {
    filters.push({
      terms: {
        'auctioneerName.keyword': facets.auctioneer,
      },
    });
  }

  if (facets.categories) {
    filters.push({
      terms: {
        'categories.keyword': facets.categories,
      },
    });
  }

  if (facets.destinations) {
    filters.push({
      terms: {
        'destinations.keyword': facets.destinations,
      },
    });
  }

  if (facets.auction) {
    filters.push({
      terms: {
        'auctionName.keyword': facets.auction,
      },
    });
  }

  if (facets.catalogParts) {
    filters.push({
      terms: {
        'catalogParts.keyword': facets.catalogParts,
      },
    });
  }

  if (facets.catalogs) {
    filters.push({
      terms: {
        'catalogs.keyword': facets.catalogs,
      },
    });
  }

  if (facets.conditions) {
    filters.push({
      terms: {
        conditions: facets.conditions,
      },
    });
  }

  if (facets.currency) {
    filters.push({
      terms: {
        currency: facets.currency,
      },
    });
  }

  if (facets.formats) {
    filters.push({
      terms: {
        formats: facets.formats,
      },
    });
  }

  if (facets.check) {
    filters.push({
      terms: {
        'check.keyword': facets.check,
      },
    });
  }

  if (facets.imageRecognition && facets.imageRecognition.length === 1) {
    filters.push({
      term: {
        imageRecognition: facets.imageRecognition[0] === '1' ? true : false,
      },
    });
  }

  if (facets.sold && facets.sold.length === 1) {
    filters.push({
      term: {
        sold: facets.sold[0] === '1' ? true : false,
      },
    });
  }

  if (facets.sold_for) {
    const range: { gte: Number | undefined; lte: Number | undefined } = {
      gte: facets.sold_for.from,
      lte: facets.sold_for.to,
    };

    filters.push({
      range: {
        soldFor_range: range,
      },
    });
  }

  if (facets.starting_bid) {
    const range: { gte: Number | undefined; lte: Number | undefined } = {
      gte: facets.starting_bid.from,
      lte: facets.starting_bid.to,
    };

    filters.push({
      range: {
        startingPrice_range: range,
      },
    });
  }

  if (facets.date) {
    const range: { gte: Date | undefined; lte: Date | undefined } = {
      gte: facets.date.from,
      lte: facets.date.to,
    };

    filters.push({
      range: {
        date_range: range,
      },
    });
  }

  return filters.length > 0 ? filters : null;
};

const generateSort = (sortKey: string) => {
  switch (sortKey) {
    case 'score':
      return [
        { category_weight: 'asc' },
        // categroy string (1. empty -> 2. )
        { ordering_number: 'asc' },
        // {"catalog_string":"asc"},
        { condition_weight: 'asc' },
        { soldFor_max: 'desc' },
        '_score',
      ];
    case 'highSold':
      return [{ soldFor_max: 'desc' }];
    case 'lowSold':
      return [{ soldFor_min: 'asc' }];
    case 'old':
      return [{ date_max: 'desc' }];
    case 'new':
      return [{ date_min: 'asc' }];
  }
};

const uploadFileForDlr = async (token: string, file: any): Promise<any> => {
  const data = new FormData();
  data.append('file', file);

  const response = await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/dlr-upload-image`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: data,
    }
  );

  return await response.json();
};

const getDlrSearchResults = async (token: string): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/dlr-search`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({}),
    }
  );

  return await response.json();
};

const getSearchResults = async (
  query: string,
  facets = {},
  sortKey: string = 'score',
  pagination = { currentPage: 1 },
  token: string,
  options = {}
): Promise<any> => {
  let QueryParameters: any;
  QueryParameters = { must: [{ match_all: {} }] };
  if (query) {
    const queryWords = query
      .match(/\S*\s*/gi)
      ?.map((s) => s.toLowerCase().trim());
    if (query.match(/^"(.*)"$/)) {
      QueryParameters = {
        must: [
          {
            multi_match: {
              query: query.slice(1, query.length - 1),
              type: 'phrase_prefix',
              fields: [
                'description',
                'auctioneerName',
                'auctionName',
                'categories',
                'catalogParts',
                'catalogNumbers.number',
                'tags',
                'orignalCategories',
              ],
            },
          },
        ],
      };
    } else if (queryWords?.indexOf('and') !== -1) {
      QueryParameters = {
        must: queryWords
          ?.filter((w) => w !== 'and' && w !== '')
          .map((word) => {
            return {
              multi_match: {
                query: word,
                type: 'phrase_prefix',
                fields: [
                  'description',
                  'auctioneerName',
                  'auctionName',
                  'categories',
                  'catalogParts',
                  'catalogNumbers.number',
                  'tags',
                  'orignalCategories',
                ],
              },
            };
          }),
      };
    } else {
      QueryParameters = {
        should: queryWords
          ?.filter((w) => w !== 'or' && w !== '')
          .map((word) => {
            return {
              multi_match: {
                query: word,
                type: 'phrase_prefix',
                fields: [
                  'description',
                  'auctioneerName',
                  'auctionName',
                  'categories',
                  'catalogParts',
                  'catalogNumbers.number',
                  'tags',
                  'orignalCategories',
                ],
              },
            };
          }),
        minimum_should_match: 1,
      };
    }
  }
  const data = {
    from: (pagination.currentPage - 1) * RESULT_COUNT,
    size: RESULT_COUNT,
    query: {
      bool: {
        ...QueryParameters,
        filter: generateFilterFromFacets(facets),
      },
    },
    sort: generateSort(sortKey),
    aggs: {
      auctioneer: {
        terms: {
          field: 'auctioneerName.keyword',
          size: 1000,
        },
      },
      auction: {
        terms: {
          field: 'auctionName.keyword',
          size: 1000,
        },
      },
      catalogParts: {
        terms: {
          field: 'catalogParts.keyword',
        },
      },
      catalogs: {
        terms: {
          field: 'catalogs.keyword',
        },
      },
      categories: {
        terms: {
          field: 'categories.keyword',
          size: 1000,
        },
      },
      destinations: {
        terms: {
          field: 'destinations.keyword',
          size: 1000,
        },
      },
      conditions: {
        terms: {
          field: 'conditions',
          size: 12,
        },
      },
      currency: {
        terms: {
          field: 'currency',
        },
      },
      formats: {
        terms: {
          field: 'formats',
        },
      },
      imageRecognition: {
        terms: {
          field: 'imageRecognition',
        },
      },
      sold: {
        terms: {
          field: 'sold',
        },
      },
      startingPrice_min: {
        stats: {
          field: 'startingPrice_min',
        },
      },
      startingPrice_max: {
        stats: {
          field: 'startingPrice_max',
        },
      },
      soldFor_min: {
        stats: {
          field: 'soldFor_min',
        },
      },
      soldFor_max: {
        stats: {
          field: 'soldFor_max',
        },
      },
      date_min: {
        stats: {
          field: 'date_min',
        },
      },
      date_max: {
        stats: {
          field: 'date_max',
        },
      },
    },
  };

  const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/search`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
  return transformFromEs(await response.json(), {
    ...pagination,
    size: RESULT_COUNT,
  });
};

const getDummyDetails = async (): Promise<any> => {
  const response = await fetch('/dummy_detail_page_response.json', {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
};

const apiService = {
  connectGroup,
  getAuctions,
  getCategories,
  getDlrSearchResults,
  getSearchResults,
  getDetails,
  getDetailsNew,
  getDummyDetails,
  deleteGroup,
  deleteEntry,
  storeNewLot,
  unGroupEntry,
  uploadFileForDlr,
  changeImageRecognition,
};

export default apiService;

import { useAuth0 } from '@auth0/auth0-react';
import { IonIcon } from '@ionic/react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core';
import Dialog from '@reach/dialog';
import { closeCircleOutline } from 'ionicons/icons';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import apiService from '../../../services/api/api.serivce';
import FeatureToggle from '../../FeatureToggle/FeatureToggle';
import styles from './Ungroup.module.scss';

const useDisconnectCall = (id: string) => {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const execute = async () => {
    setResult(false);
    setError(false);
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    apiService
      .unGroupEntry(id, accessToken)
      .then(() => {
        setLoading(false);
        setResult(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return { isLoading, execute, result, error };
};

const Ungroup: React.FC<{ onSelected: any; data: any; disabled: boolean }> = ({
  onSelected,
  data,
  disabled,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showDialog, setShowDialog] = React.useState(false);
  const [keepTags, setKeepTags] = React.useState(false);
  const { isLoading, execute, result, error } = useDisconnectCall(data.id);

  const open = () => {
    onSelected();
    setShowDialog(true);
  };

  const close = useCallback(() => {
    if (!isLoading) setShowDialog(false);
  }, [isLoading, setShowDialog]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Could not disconnect. Please try again', {
        variant: 'warning',
      });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (result) {
      enqueueSnackbar(
        'This entry was marked to be disconnected. It might take a couple of minutes for your request to be reflected.',
        {
          variant: 'success',
        }
      );
      close();
    }
  }, [result, enqueueSnackbar, close]);

  const handleTagsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setKeepTags(checked);
  };

  return (
    <>
      <Dialog isOpen={showDialog} onDismiss={close}>
        <div className={styles.ModalHeader}>
          <h1 className={styles.ModalTitle}>Delete group</h1>
          <button className={styles.CloseButton} onClick={close}>
            <IonIcon icon={closeCircleOutline} ariaLabel="Close Modal" />
          </button>
        </div>
        <div className={styles.ModalContent}>
          <div>Do you really want to disconnect this entry from the group?</div>
          <FeatureToggle featureName="DISCONNECT_KEEP_TAGS">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={keepTags}
                  onChange={handleTagsChange}
                  name="keepTags"
                />
              }
              label="Keep the tags on the new entry?"
            />
          </FeatureToggle>
        </div>
        <div className={styles.ModalActions}>
          <Button type="button" variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={execute}>
            {isLoading ? 'Loading ...' : 'Yes, remove entry from the group'}
          </Button>
        </div>
      </Dialog>
      <MenuItem onClick={open} disabled={disabled}>
        Disconnect from Group
      </MenuItem>
    </>
  );
};

export default Ungroup;
